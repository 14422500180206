/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.vs-match {
	margin-right: 10px; 
	font-weight: bold;
	font-size: 20px;
	background: linear-gradient(red, yellow, green);
	padding: 0 10px 0 10px;
	display: block;
	width: 50px;
	border-radius: 6px;
}

// Iframe video 
.video__content {
	// position: relative;
	// overflow: hidden;
	// height: 600px;

	.ske-sport-title-video {
		position: absolute;
		z-index: 0;
		bottom: 0px;
		left: 195px;
		// background-image: linear-gradient(red, orange);
		// background-image: linear-gradient(to left, rgba(255,0,0,0), rgba(255,0,0,1));
		background-image: linear-gradient(to right, black 0%, yellow 100%);
		
		display: flex;
		align-items: center;
		height: 38px;
		width: 420px;
		padding: 0 10px;

		p {
			font-size: 14px;
			span {
				font-size: 16px;
				font-weight: bold;
			}
		}
	}
}

.video iframe {
	display: block;       /* iframes are inline by default */
	border: none;         /* Reset default border */
	width: 100%;
}
